import { mapState } from 'vuex'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'

@Component({
  name: 'GtrRegistrationSettingsView',
  computed: {
    ...mapState('formbuilder', ['reportableEventFields']),
    ...mapState('option', ['option_groups']),
    ...mapState('setting', ['settings']),
    ...mapState('company', ['companyBlacklists']),
    ...mapState('email', ['emails']),
    ...mapState('event', ['event', 'eventAllContent', 'changelog', 'currentlyDeployedLiveUUID', 'currentDevUUID', 'mcProFields', 'mcTradeFields', 'participant_fields']),
    ...mapState('contentpages', ['contentpages']),
    ...mapState('adminUser', ['users'])
  },
  components: {
    changelog: ChangeLog
  }
})

export default class GtrRegistrationModuleFormView extends GtrSuper {
  reportableEventFields!: Record<string, any>
  users!: Record<string, any>

  option_groups!: Record<string, any>

  data () {
    return {
      regTypeGtrMcPro: '',
      memberTypeMcPro: '',
      regTypeGtrMcTrade: '',
      memberTypeMcTrade: '',
      memberTypesToRegTypesMcPro: {},
      memberTypesToRegTypesMcTrade: {},
      participant_fields_items_for_select: [],
      mcProMapping: {},
      mcTradeMapping: {},
      loadingMcProFields: false,
      loadingMcTradeFields: false,
      showMcProModal: false,
      showMcTradeModal: false,
      tab: 0,
      changeLogModel: {
        changeLog: [],
        currentDevUUID: null,
        currentlyDeployedLiveUUID: null
      },
      settingsVersion: null,
      settingsDefault: {
        custom_registration_closed_verbiage: '',
        login_closed_verbiage: '',
        allow_updates_when_closed: false,
        require_reg_type_before_registering: '',
        use_registration_form_to_add_participants: '',
        display_option_prices: '',
        show_option_details_on_form: '',
        regtype_selection_verbiage: '',
        total_registrations_cap: null,
        custom_registration_capped_verbiage: '',
        allow_transfers: false,
        allow_cancellations: false,
        cancellation_verbiage: 'Your registration has been cancelled',
        require_email_to_register: true,
        editing_disabled: false,
        editing_disabled_verbiage: '',
        clear_discountcodes_for_incompletes: false,
        clear_options_for_canceled: false,
        google_translate_enabled: false,
        login_fields: {
          label: {
            en: ''
          },
          field: '',
          error: ''
        },
        list_view_fields: {
          label: {
            en: ''
          },
          field: '',
          error: ''
        },
        custom_js: '',
        group_registration_email_style: '',
        group_registration_email_recipient: '',
        group_registration_prepoulated_fields: [],
        calendar_download: false,
        tracking_code: '',
        autohide_capped_options: false,
        registration_approval_required: false,
        registration_approval_admin: null,
        idp_login_enabled: false,
        a2z_event_id: '',
        a2z_tenant_id: '',
        a2z_contact_type: '',
        a2z_set_reg_type: '',
        mcpro_login_enabled: false,
        mcpro_subdomain: '',
        mcpro_client_id: '',
        mcpro_client_secret: '',
        mcpro_mapping: [],
        mctrade_login_enabled: false,
        mctrade_client_id: '',
        mctrade_client_secret: '',
        mctrade_tenant: '',
        mctrade_mapping: [],
        require_invite: false,
        invite_verbiage: 'You must be invited to attend',
        blacklisted_emails: '',
        blacklisted_email_error: '',
        invalid_emails: '',
        invalid_email_error: '',
        auth_field: {
          label: {
            en: ''
          },
          field: '',
          error: ''
        },
        require_auth_field: false,
        require_auth_field_before_complete: false,
        require_auth_field_verbiage: '',
        allow_duplicate_emails_after_login: false,
        tax_rate: 0,
        group_max_participants: 1000,
        tax_type: '',
        invoice_prefix: '',
        invoice_body: '',
        first_page_override: '',
        confirmation_email_enabled: false,
        next_button_text: '',
        previous_button_text: '',
        login_button_text: '',
        cancel_registration_button_text: '',
        save_and_finish_button_text: '',
        transfer_registration_button_text: '',
        close_button_text: ''
      },
      settingsObject: {
        registration_closed: {
          _default: false
        },
        registration_timed_close: {
          _default: true
        },
        registration_timed_open: {
          _default: true
        },
        login_timed_close: {
          _default: true
        },
        login_timed_open: {
          _default: true
        },
        registration_opens_after: {
          _default: null
        },
        registration_close_time: {
          _default: null
        },
        login_opens_after: {
          _default: null
        },
        login_closed_after: {
          _default: null
        },
        allow_duplicate_emails_after_login: {},
        autohide_capped_options: {},
        registration_approval_required: {},
        registration_approval_admin: {},
        idp_login_enabled: {},
        a2z_event_id: {},
        a2z_tenant_id: {},
        a2z_contact_type: {},
        a2z_set_reg_type: { _default: null },
        mcpro_login_enabled: {},
        mcpro_subdomain: {},
        mcpro_client_id: {},
        mcpro_client_secret: {},
        mcpro_mapping: {},
        mctrade_login_enabled: {},
        mctrade_client_id: {},
        mctrade_client_secret: {},
        mctrade_tenant: {},
        mctrade_mapping: {},
        custom_registration_closed_verbiage: {},
        login_closed_verbiage: {},
        allow_updates_when_closed: {},
        require_reg_type_before_registering: {},
        use_registration_form_to_add_participants: {},
        display_option_prices: {},
        regtype_selection_verbiage: {},
        total_registrations_cap: {},
        custom_registration_capped_verbiage: {},
        allow_transfers: {},
        allow_cancellations: {},
        cancellation_verbiage: {},
        require_email_to_register: {},
        editing_disabled: {},
        editing_disabled_verbiage: {},
        clear_discountcodes_for_incompletes: {},
        clear_options_for_canceled: {},
        google_translate_enabled: {},
        login_fields: {},
        list_view_fields: {},
        custom_js: {},
        group_registration_email_style: {},
        group_registration_email_recipient: {},
        group_registration_prepoulated_fields: {},
        calendar_download: {},
        tracking_code: {},
        require_invite: {},
        invite_verbiage: {},
        blacklisted_emails: {},
        blacklisted_email_error: {},
        invalid_emails: {},
        invalid_email_error: {},
        auth_field: {},
        require_auth_field: {},
        require_auth_field_before_complete: {},
        require_auth_field_verbiage: {},
        first_page_override: {},
        tax_rate: {},
        group_max_participants: {},
        tax_type: {},
        invoice_prefix: {},
        invoice_body: {},
        confirmation_email_enabled: {},
        next_button_text: {},
        previous_button_text: {},
        login_button_text: {},
        cancel_registration_button_text: {},
        save_and_finish_button_text: {},
        transfer_registration_button_text: {},
        close_button_text: {},
        show_option_details_on_form: {}
      },
      fieldsPrint: [],
      fieldsPrintAuthField: [],
      groupRegistrationEmailConfirmationItems: [
        {
          text: 'Each Participant',
          value: 'EACH_PARTICIPANT'
        },
        {
          text: 'Only Parent Emailed',
          value: 'ONLY_PARENT'
        },
        {
          text: 'Parent copied on all emails',
          value: 'PARENT_COPIED'
        }
      ],
      groupRegistrationEmailRecipientConfirmationItems: [
        {
          text: 'Parent',
          value: 'PARENT'
        },
        {
          text: 'All group members',
          value: 'ALL_GROUP_MEMBERS'
        }
      ],
      selectAllPrepopulatedGroupRegFields: false
    }
  }

  async mounted () {
    await this.fetchEvent()
    await this.initializeSettingsObject()
    this.toggleSwitchesOnIfDefaultIsOn()
    this.updateInviteVerbiage()
    this.updateCancellationVerbiage()
    await this.fetchSettings()
    const promises: Promise<any>[] = [this.fetchOptionGroups(), this.fetchReportableEventFields(), this.fetchCompanyBlacklists(), this.fetchEmails(), this.fetchContentpages(), this.fetchUsers(), this.fetchChangelog(), this.fetchCurrentlyDeployedLiveUUID()
    ]
    await Promise.all(promises)
    this.setValueForRegistrationClosed(this.$data.settingsObject.registration_closed._default)
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload) {
      this.$data.changeLogModel.changeLog = payload.data
    }
  }

  @Watch('mcProFields')
  onMcProFieldsChange (payload) {
    if (payload) {
      for (const fieldIndex in payload.fields) {
        const field = payload.fields[fieldIndex]
        if (this.$data.mcProMapping[field.name] === undefined) {
          this.$data.mcProMapping[field.name] = null
        }
      }
    }
  }

  @Watch('currentDevUUID')
  onCurrentDevUUIDChange (payload: any) {
    if (payload.dev) {
      this.$data.changeLogModel.currentDevUUID = payload.dev.uuid
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload.live) {
      this.$data.changeLogModel.currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  @Watch('settingsVersion', { deep: true })
  async onSettingsVersionChange (settings_uuid: string) {
    if (settings_uuid) {
      const response = await this.$store.dispatch('setting/getSettingsByUuid', {
        event_uuid: this.$route.params.event_uuid,
        settings_uuid
      })
      if (response) {
        const settings = response.data.page_data
        for (const settingName in settings) {
          this.$data.settingsObject[settingName] = settings[settingName]
        }
        this.$data.changeLogModel.currentDevUUID = settings_uuid
      }
      Container.get(Notification).success('Settings version successfully changed.')
    }
  }

  private async fetchChangelog () {
    try {
      const changelogData = {
        type: 'settings',
        event_uuid: this.$route.params.event_uuid,
        subtype: 'default/all?no_data=true'
      }
      const deployData = { type: 'settings', sub_type: 'default', event_uuid: this.$route.params.event_uuid }
      await this.$store.dispatch('event/getChangelog', changelogData)
      await this.$store.dispatch('event/getCurrentlyDeployedLiveUUID', deployData)
      await this.$store.dispatch('event/getCurrentDevUUID', deployData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCurrentlyDeployedLiveUUID () {
    try {
      const deployLiveData = {
        type: 'settings',
        event_uuid: this.$route.params.event_uuid,
        sub_type: 'default'
      }
      await this.$store.dispatch('event/getCurrentlyDeployedLiveUUID', deployLiveData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async handleDeployed () {
    await this.fetchChangelog()
  }

  handleUpdateSettingsVersion (payload: any) {
    if (payload) {
      this.$data.settingsVersion = payload
    }
  }

  async initializeSettingsObject () {
    if (Object.keys((this as any).eventAllContent?.settings).length) {
      for (const settingName in (this as any).eventAllContent?.settings) {
        if (['registration_opens_after', 'registration_close_time', 'login_opens_after', 'login_closed_after', 'registration_timed_close', 'registration_timed_open', 'login_timed_close', 'login_timed_open'].includes(settingName)) {
          continue
        } else {
          if (this.$data.settingsObject?.[settingName]) {
            Vue.set(this.$data.settingsObject[settingName], '_default', (this as any).eventAllContent?.settings[settingName])
          }
        }
      }
      if ((this as any).eventAllContent?.settings.mcpro_mapping !== null) {
        const mapping = (this as any).eventAllContent?.settings.mcpro_mapping
        for (const itemIndex in mapping) {
          const item = mapping[itemIndex]
          this.$data.mcProMapping[item.external_field] = item.field
        }
      }
      if ((this as any).eventAllContent?.settings.mctrade_mapping !== null) {
        const mapping = (this as any).eventAllContent?.settings.mctrade_mapping
        for (const itemIndex in mapping) {
          const item = mapping[itemIndex]
          this.$data.mcTradeMapping[item.external_field] = item.field
        }
      }
      if ((this as any).eventAllContent?.settings.mcpro_member_type_mapping !== null) {
        this.$data.memberTypesToRegTypesMcPro = {}
        for (const itemIndex in (this as any).eventAllContent?.settings.mcpro_member_type_mapping) {
          const value = (this as any).eventAllContent?.settings.mcpro_member_type_mapping[itemIndex]
          this.$data.memberTypesToRegTypesMcPro[itemIndex] = value
        }
      }
      if ((this as any).eventAllContent?.settings.mctrade_member_type_mapping !== null) {
        this.$data.memberTypesToRegTypesMcTrade = {}
        for (const itemIndex in (this as any).eventAllContent?.settings.mctrade_member_type_mapping) {
          const value = (this as any).eventAllContent?.settings.mctrade_member_type_mapping[itemIndex]
          this.$data.memberTypesToRegTypesMcTrade[itemIndex] = value
        }
      }
    }
  }

  private updateInviteVerbiage () {
    // nested destructuring for the win.
    const {
      invite_verbiage: {
        _default: {
          en: message
        }
      }
    } = this.$data.settingsObject
    this.$data.settingsDefault.invite_verbiage = message
  }

  private updateCancellationVerbiage () {
    const {
      cancellation_verbiage: {
        _default: {
          en: message
        }
      }
    } = this.$data.settingsObject
    this.$data.settingsDefault.cancellation_verbiage = message
  }

  async showMcProFieldModal () {
    await this.updateSettings()
    this.$data.showMcProModal = true
    this.$data.loadingMcProFields = true
    await this.$store.dispatch('event/getParticipantFields', this.$route.params.event_uuid)
    await this.$store.dispatch('event/getMcProFields', this.$route.params.event_uuid)
    this.$data.loadingMcProFields = false
  }

  async showMcTradeFieldModal () {
    await this.updateSettings()
    this.$data.showMcTradeModal = true
    this.$data.loadingMcTradeFields = true
    await this.$store.dispatch('event/getParticipantFields', this.$route.params.event_uuid)
    await this.$store.dispatch('event/getMcTradeFields', this.$route.params.event_uuid)
    this.$data.loadingMcTradeFields = false
  }

  finishMcProFieldMapping () {
    this.$data.showMcProModal = false
  }

  finishMcTradeFieldMapping () {
    this.$data.showMcTradeModal = false
  }

  private toggleSwitchesOnIfDefaultIsOn () {
    for (const settingName in this.$data.settingsObject) {
      if (this.$data.settingsObject[settingName]._default === true) {
        this.$data.settingsDefault[settingName] = true
      }
      if (settingName === 'registration_approval_admin' || settingName === 'a2z_tenant_id' || settingName === 'a2z_event_id' || settingName === 'a2z_contact_type' || settingName === 'a2z_set_reg_type' || settingName === 'mcpro_subdomain' || settingName === 'mcpro_client_id' || settingName === 'mcpro_client_secret' || settingName === 'mctrade_client_id' || settingName === 'mctrade_client_secret' || settingName === 'mctrade_tenant') {
        this.$data.settingsDefault[settingName] = this.$data.settingsObject[settingName]._default
      }
    }
  }

  async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get invoiceEmail () {
    if ((this as any).emails.length) {
      return (this as any).emails.filter(email => email.key === 'invoice')?.pop()?.value
    }
    return null
  }

  private goToInvoiceEmail () {
    const routeData = this.$router.resolve({ name: 'level-two.event.emails.edit', params: { event_uuid: this.$route.params.event_uuid, email_uuid: this.invoiceEmail.uuid } })
    window.open(routeData.href, '_blank')
  }

  private async fetchEmails () {
    try {
      await this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCompanyBlacklists () {
    try {
      await this.$store.dispatch('company/getCompanyBlacklists', { company_uuid: this.$route.params.uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async fetchOptionGroups () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSettings () {
    try {
      await this.$store.dispatch('setting/getSettings', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code === 'CONTENT_NOT_FOUND') {
        this.$store.commit('setting/SET_SETTINGS', [])
        Container.get(ErrorHandlerService).error(error)
      }
    }
  }

  get contentPageItems () {
    if (Object.keys((this as any).contentpages).length) {
      const items: string[] = []
      for (const page in (this as any).contentpages) {
        items.push((this as any).contentpages[page].page_name)
      }
      return items
    }
    return []
  }

  private removeListViewField (fieldToRemove: string) {
    this.$data.settingsObject.list_view_fields._default = this.$data.settingsObject.list_view_fields._default.filter(field => field.field !== fieldToRemove)
  }

  get adminList () {
    const usersRes: any[] = []
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i]
      if (user.company && user.company.uuid === (this as any).eventAllContent.event.company.uuid) {
        usersRes.push({ text: user.name, value: user.uuid })
      }
    }
    return usersRes
  }

  @Watch('reportableEventFields', { immediate: true })
  @Watch('option_groups', { immediate: true })
  populateFieldsPrint () {
    const participants = this.reportableEventFields?.participants
    if (participants && Object.keys(participants) && Array.isArray(this.option_groups)) {
      this.$data.fieldsPrint = []
      this.$data.fieldsPrintAuthField = []
      for (const fieldIndex in participants) {
        const field = participants[fieldIndex]
        this.$data.fieldsPrint.push({
          text: field.label,
          value: field.field
        })
        if (field.field === 'login_key' || field.field === 'uuid' || field.field === 'barcodes') {
          this.$data.fieldsPrintAuthField.push({
            text: field.label,
            value: field.field
          })
        }
      }
      for (let i = 0; i < this.option_groups.length; i++) {
        this.$data.fieldsPrint.push({
          text: this.option_groups[i].name,
          value: this.option_groups[i].uuid
        })
      }
    }
  }

  get fieldsPrintForListViewFields () {
    const listViewFields = (this.$data.settingsObject.list_view_fields?._default || []).map(field => field.field)
    return this.$data.fieldsPrint.filter(field => !listViewFields.includes(field.value))
  }

  // TODO: Refactor this. - zsb
  private updateSetting (settingName: string, registrationType: string | null, value: any) {
    if (this.$data.settingsObject?.[settingName] && registrationType) {
      // TODO(zb): Refactor this.
      // Make sure that show option details on form is only default.
      if (['show_option_details_on_form'].includes(settingName)) {
        this.$data.settingsObject[settingName] = {
          _default: value
        }
      } else if (['list_view_fields'].includes(settingName)) {
        if (value.label.en) {
          this.$data.settingsObject[settingName][registrationType].push({
            field: value.field,
            label: {
              en: value.label.en
            },
            type: 'field'
          })
          this.$data.settingsDefault.list_view_fields = {
            label: {
              en: ''
            },
            field: '',
            error: ''
          }
        } else {
          this.$data.settingsDefault.list_view_fields.error = 'Label is required.'
        }
      } else if (['login_fields'].includes(settingName)) {
        if (value.label.en) {
          this.$data.settingsObject[settingName][registrationType] = [{
            label: {
              en: ''
            },
            field: ''
          }]
          this.$data.settingsDefault.login_fields.error = ''
          this.$data.settingsObject[settingName][registrationType][0].label.en = value.label.en
          this.$data.settingsObject[settingName][registrationType][0].field = value.field
        } else {
          this.$data.settingsDefault.login_fields.error = 'Label is required.'
        }
      } else if (['auth_field'].includes(settingName)) {
        if (value.label.en) {
          this.$data.settingsObject[settingName][registrationType] = {
            label: {
              en: ''
            },
            field: ''
          }
          this.$data.settingsDefault.auth_field.error = ''
          this.$data.settingsObject[settingName][registrationType].label.en = value.label.en
          this.$data.settingsObject[settingName][registrationType].field = value.field
        } else {
          this.$data.settingsDefault.auth_field.error = 'Label is required.'
        }
      } else if (['tax_type', 'login_closed_verbiage', 'regtype_selection_verbiage', 'custom_registration_capped_verbiage', 'editing_disabled_verbiage', 'blacklisted_email_error', 'invalid_email_error', 'invoice_body', 'next_button_text', 'previous_button_text', 'login_button_text', 'cancel_registration_button_text', 'save_and_finish_button_text', 'transfer_registration_button_text', 'close_button_text', 'require_auth_field_verbiage'].includes(settingName)) {
        this.$data.settingsObject[settingName][registrationType] = {
          en: value
        }
      } else {
        this.$data.settingsObject[settingName][registrationType] = value
      }
    } else {
      const currentInviteVerbiageSetting = this.$data.settingsObject[settingName]
      for (const key in currentInviteVerbiageSetting) {
        currentInviteVerbiageSetting[key] = {
          en: value
        }
      }
      this.$data.settingsObject[settingName] = currentInviteVerbiageSetting
      const currentCancelledVerbiageSetting = this.$data.settingsObject[settingName]
      for (const key in currentCancelledVerbiageSetting) {
        currentCancelledVerbiageSetting[key] = {
          en: value
        }
      }
      this.$data.settingsObject[settingName] = currentCancelledVerbiageSetting
    }
  }

  private async fetchReportableEventFields () {
    try {
      await this.$store.dispatch('formbuilder/getReportableEventFields', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  /**
   * will update the settings object with uuids of custom registration types.
   * @returns {void}
   */
  private makeChangesToSettingsObject () {
    const registrationTypeGroupOptions = this.option_groups.filter(group => group.name === 'Registration Types').pop()?.options || null
    if (registrationTypeGroupOptions) {
      const registrationTypes = [{ name: 'Default', uuid: '_default' }, ...registrationTypeGroupOptions.map(option => ({ name: option.name, uuid: option.uuid }))]
      for (const settingName in this.$data.settingsObject) {
        if (['registration_opens_after', 'registration_close_time', 'login_opens_after', 'login_closed_after'].includes(settingName)) {
          const value = (this as any).settings?.page_data?.[settingName]?._default ?? null
          this.$data.settingsObject[settingName]._default = value ? this.convertFromUtcToEventTimezone(value) : null
        }
        if (['registration_opens_after', 'registration_close_time', 'login_opens_after', 'login_closed_after', 'registration_timed_close', 'registration_timed_open', 'login_timed_close', 'login_timed_open'].includes(settingName)) {
          continue
        }
        if (!Object.keys(((this as any).settings?.page_data || {})).length) {
          return
        }
        this.$data.settingsObject[settingName] = {}
        for (const registrationType of registrationTypes) {
          if (['list_view_fields'].includes(settingName) && registrationType.uuid !== '_default') {
            continue
          }
          let value = (this as any).settings?.page_data?.[settingName]?.[registrationType.uuid] ?? null
          if (!(this as any).settings?.page_data.hasOwnProperty(settingName)) {
            value = (this as any).eventAllContent?.settings[settingName]
          }
          if (value === null && registrationType.uuid === '_default') {
            if (['auth_field'].includes(settingName)) {
              value = {
                label: {
                  en: 'Login Key'
                },
                field: 'login_key'
              }
            }
            if (['login_fields'].includes(settingName)) {
              value = [{
                label: {
                  en: 'Email'
                },
                field: 'email'
              }]
            }
            if (['list_view_fields'].includes(settingName)) {
              value = []
            }
            if (['tax_type', 'custom_registration_closed_verbiage', 'login_closed_verbiage', 'regtype_selection_verbiage', 'custom_registration_capped_verbiage', 'editing_disabled_verbiage', 'blacklisted_email_error', 'invalid_email_error', 'invoice_body', 'next_button_text', 'previous_button_text', 'login_button_text', 'cancel_registration_button_text', 'save_and_finish_button_text', 'transfer_registration_button_text', 'close_button_text'].includes(settingName)) {
              value = {
                en: ''
              }
            }
          }
          if (value !== null || registrationType.uuid === '_default') {
            Vue.set(this.$data.settingsObject[settingName], registrationType.uuid, value)
          }
        }
      }
    }
  }

  changeBackRegistrationClosed () {
    const currentValue = this.$data.settingsObject.registration_closed._default
    this.setValueForRegistrationClosed(!currentValue)
  }

  setValueForRegistrationClosed (setValue) {
    this.$data.settingsObject.registration_closed = { _default: setValue }
  }

  @Watch('option_groups')
  onOptionGroupsChange (payload: any) {
    if (payload) {
      this.makeChangesToSettingsObject()
    }
  }

  get companyBlacklistItems () {
    if ((this as any)?.companyBlacklists.length) {
      const blacklistItems: { text: string; value: string }[] = []
      for (let i = 0; i < (this as any)?.companyBlacklists.length; i++) {
        blacklistItems.push({
          text: (this as any).companyBlacklists[i]?.name,
          value: (this as any).companyBlacklists[i]?.uuid
        })
      }
      return blacklistItems
    }
    return []
  }

  private getCompanyBlacklistByName (uuid: string): string {
    const blacklist = this.companyBlacklistItems.filter(item => item.value === uuid).pop()
    return blacklist?.text ?? ''
  }

  get registrationTypes () {
    const registrationTypeGroupOptions = (this as any)?.option_groups.filter(group => group.name === 'Registration Types').pop()?.options || null
    if (registrationTypeGroupOptions) {
      const registrationTypes = registrationTypeGroupOptions.map(option => ({ name: option.name, uuid: option.uuid }))
      return [{ name: 'Default', uuid: '_default' }, ...registrationTypes]
    }
    return []
  }

  get registrationTypesPlainText () {
    const registrationTypeGroupOptions = (this as any)?.option_groups.filter(group => group.name === 'Registration Types').pop()?.options || null
    if (registrationTypeGroupOptions) {
      const registrationTypes = registrationTypeGroupOptions.map(option => ({ text: option.name, value: option.name }))
      return [{ text: 'Default', value: '_default' }, ...registrationTypes]
    }
    return []
  }

  get registrationTypesPlainTextA2z () {
    const registrationTypeGroupOptions = (this as any)?.option_groups.filter(group => group.name === 'Registration Types').pop()?.options || null
    if (registrationTypeGroupOptions) {
      const registrationTypes = registrationTypeGroupOptions.map(option => ({ text: option.name, value: option.name }))
      return [{ text: 'Default', value: '' }, ...registrationTypes]
    }
    return []
  }

  private getRegistrationTypeNameByUuid (uuid: string) {
    if (uuid === '_default') {
      return 'Default'
    }
    const registrationTypeGroupOptions = (this as any)?.option_groups.filter(group => group.name === 'Registration Types').pop()?.options || null
    if (registrationTypeGroupOptions) {
      const correctGroup = registrationTypeGroupOptions.filter(option => option.uuid === uuid).pop()
      if (correctGroup) {
        return correctGroup.name
      }
    }
    return ''
  }

  private addSavedTextToDefaultObject (defaultSettingName: string, value: any) {
    if (['auth_field', 'tax_type', 'login_closed_verbiage', 'regtype_selection_verbiage', 'custom_registration_capped_verbiage', 'editing_disabled_verbiage', 'blacklisted_email_error', 'invalid_email_error', 'invoice_body', 'next_button_text', 'previous_button_text', 'login_button_text', 'cancel_registration_button_text', 'save_and_finish_button_text', 'transfer_registration_button_text', 'close_button_text'].includes(defaultSettingName)) {
      this.$data.settingsDefault[defaultSettingName] = value.en
    } else if (['login_fields'].includes(defaultSettingName)) {
      this.$data.settingsDefault[defaultSettingName] = value[0]
    } else {
      this.$data.settingsDefault[defaultSettingName] = value
    }
  }

  private removeText (settingName: string, registrationTypeUuid: string) {
    if (['auth_field'].includes(settingName)) {
      this.$data.settingsObject[settingName][registrationTypeUuid] = {
        label: {
          en: 'Login Key'
        },
        field: 'login_key'
      }
    } else if (['tax_type', 'custom_registration_closed_verbiage', 'login_closed_verbiage', 'regtype_selection_verbiage', 'custom_registration_capped_verbiage', 'editing_disabled_verbiage', 'blacklisted_email_error', 'invalid_email_error', 'invoice_body', 'next_button_text', 'previous_button_text', 'login_button_text', 'cancel_registration_button_text', 'save_and_finish_button_text', 'transfer_registration_button_text', 'close_button_text'].includes(settingName)) {
      this.$data.settingsObject[settingName][registrationTypeUuid] = {
        en: ''
      }
    } else {
      this.$data.settingsObject[settingName][registrationTypeUuid] = null
    }
  }

  private async fetchUsers () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('adminUser/fetchAdminUsers')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchContentpages () {
    try {
      await this.$store.dispatch('contentpages/getContentPages', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async updateSettings () {
    try {
      this.$data.loading = true
      for (const settingName in this.$data.settingsObject) {
        if (['registration_opens_after', 'registration_close_time', 'login_opens_after', 'login_closed_after'].includes(settingName)) {
          this.$data.settingsObject[settingName]._default = this.$data.settingsObject[settingName]._default ? this.convertFromEventTimezoneToUtc(this.$data.settingsObject[settingName]._default) : null
        }
      }
      const settingsWithOnlyDefault = ['display_option_prices']
      for (const settingName in this.$data.settingsObject) {
        if (settingsWithOnlyDefault.includes(settingName)) {
          this.$data.settingsObject[settingName] = { _default: this.$data.settingsObject[settingName]._default }
        }
      }
      const mcProDataReformatted: any[] = []
      for (const fieldNameMc in this.$data.mcProMapping) {
        const fieldNameUs = this.$data.mcProMapping[fieldNameMc]
        if (fieldNameUs && fieldNameMc) {
          mcProDataReformatted.push({ external_field: fieldNameMc, field: fieldNameUs, valueType: 'field' })
        }
      }
      const mcTradeDataReformatted: any[] = []
      for (const fieldNameMc in this.$data.mcTradeMapping) {
        const fieldNameUs = this.$data.mcTradeMapping[fieldNameMc]
        if (fieldNameUs && fieldNameMc) {
          mcTradeDataReformatted.push({ external_field: fieldNameMc, field: fieldNameUs, valueType: 'field' })
        }
      }

      const settingsObjectWithNewValue = this.$data.settingsObject
      settingsObjectWithNewValue.a2z_set_reg_type = { _default: this.$data.settingsDefault.a2z_set_reg_type }
      settingsObjectWithNewValue.mcpro_mapping = { _default: mcProDataReformatted }
      settingsObjectWithNewValue.mctrade_mapping = { _default: mcTradeDataReformatted }
      settingsObjectWithNewValue.mcpro_member_type_mapping = { _default: this.$data.memberTypesToRegTypesMcPro }
      settingsObjectWithNewValue.mctrade_member_type_mapping = { _default: this.$data.memberTypesToRegTypesMcTrade }
      await this.$store.dispatch('setting/saveSettings', {
        event_uuid: this.$route.params.event_uuid,
        data: settingsObjectWithNewValue
      })
      const deployData = { type: 'settings', sub_type: 'default', event_uuid: this.$route.params.event_uuid }
      await this.$store.dispatch('event/getCurrentDevUUID', deployData)
      Container.get(Notification).success('Settings successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      for (const settingName in this.$data.settingsObject) {
        if (['registration_opens_after', 'registration_close_time', 'login_opens_after', 'login_closed_after'].includes(settingName)) {
          this.$data.settingsObject[settingName]._default = this.$data.settingsObject[settingName]._default ? this.convertFromUtcToEventTimezone(this.$data.settingsObject[settingName]._default) : null
        }
      }
    }
  }

  onErrorMcKey () {
    Container.get(Notification).error('There was an error copying the MC Pro key. Please refresh and try again.')
  }

  onCopyMcKey () {
    Container.get(Notification).success('MC Pro Url successfully copied.')
  }

  get mcProRedirectUrl () {
    const event = this.$store.state.event.event
    if (event?.event_identifier) {
      return `${process.env.VUE_APP_REG_URL}/${event.event_identifier}/mcpro-return`
    }
  }

  get regFields () {
    return [{ text: 'test', value: 'test' }]
  }

  private addOptionsGroups (participant_fields_items: any) {
    this.option_groups.forEach((group) => {
      participant_fields_items.push({
        text: `Option Group: ${group.name}`,
        value: group.uuid,
        used: (group.on_field !== null)
      })
    })
    return participant_fields_items
  }

  @Watch('participant_fields')
  onParticipantFieldsChange (payload) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        // don't add fields that are tied to option groups.
        if (payload[i].option_group_uuid) continue
        if (!this.excludedParticipantImportFields.includes(payload[i].field)) {
          this.$data.participant_fields_items_for_select.push({
            text: payload[i].label,
            value: payload[i].field,
            used: payload[i].used,
            custom_field: payload[i].custom_field
          })
        }
      }
    }
  }

  get fieldItemsGo () {
    return this.addOptionsGroups(this.$data.participant_fields_items_for_select)
  }

  addMemberTypeMappingMcPro () {
    Vue.set(this.$data.memberTypesToRegTypesMcPro, this.$data.memberTypeMcPro, this.$data.regTypeGtrMcPro)
    this.$data.memberTypeMcPro = ''
    this.$data.regTypeGtrMcPro = ''
  }

  deleteMemberTypeMcPro (item) {
    Vue.delete(this.$data.memberTypesToRegTypesMcPro, item)
  }

  addMemberTypeMappingMcTrade () {
    Vue.set(this.$data.memberTypesToRegTypesMcTrade, this.$data.memberTypeMcTrade, this.$data.regTypeGtrMcTrade)
    this.$data.memberTypeMcTrade = ''
    this.$data.regTypeGtrMcTrade = ''
  }

  deleteMemberTypeMcTrade (item) {
    Vue.delete(this.$data.memberTypesToRegTypesMcTrade, item)
  }

  get prepopulatedGroupRegFields () {
    const prepopulatedFields: any[] = []
    const fieldsToExclude: any[] = ['first_name', 'last_name', 'email', 'profile_photo', 'pay_for_another', 'payment_method', 'payment_details']
    const eventAllContent = this.$store.state.event.eventAllContent
    const fieldsOnForm = eventAllContent.form.flatMap(page => page.fields)
    fieldsOnForm.forEach(field => {
      if (!fieldsToExclude.includes(field.field) && field.label.en) {
        prepopulatedFields.push({
          text: field.label.en,
          value: field.field
        })
      }
    })
    return prepopulatedFields
  }

  updateSelectAllPrepopulatedGroupRegFields () {
    if (this.$data.selectAllPrepopulatedGroupRegFields) {
      this.prepopulatedGroupRegFields.forEach(field => this.$data.settingsDefault.group_registration_prepoulated_fields.push(field.value))
    } else {
      this.$data.settingsDefault.group_registration_prepoulated_fields = []
    }
  }
}
